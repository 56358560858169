<template>
  <button
    v-if="user && !isUserValid"
    class="logout-btn"
    :onClick="handleLogout"
  >
    Logout
  </button>
  <Navigation />
  <AudioPlayer />
  <div @click="closeNav" class="app" :class="{ 'app--with-nav': isNavOpen }">
    <router-view />
  </div>
</template>

<script>
import firebase from 'firebase'
import Navigation from './components/Navigation'
import AudioPlayer from './components/AudioPlayer'

export default {
  name: 'App',
  components: { Navigation, AudioPlayer },
  computed: {
    user() {
      return this.$store.state.users.currentUser
    },
    isUserValid() {
      return this.$store.state.users.isUserValid
    },
    isNavOpen() {
      return this.$store.state.app.isNavOpen
    },
  },
  methods: {
    handleLogout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch('setUser', null)
          this.$store.dispatch('setNavOpen', false)
          this.$router.push('/authenticate')
        })
    },
    closeNav() {
      this.$store.dispatch('setNavOpen', false)
    },
  },
}
</script>

<style lang="scss">
@import '_global.scss';

#app {
  font-family: 'Space Mono', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-image: repeating-linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.05),
      hsla(0, 0%, 100%, 0.05) 5px,
      transparent 0,
      transparent 7px
    ),
    radial-gradient(circle, #081218 10%, $bg 80%, $bg 100%);
}

.app {
  width: 100%;
  padding: 80px 50px;
  max-width: 1200px;
  transition: $duration-nav;
  margin-left: 0;

  &--with-nav {
    @media only screen and (min-width: 768px) {
      margin-left: $nav-width;
    }
  }
}

button {
  border: 1px solid white;
  color: white;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 2px;
  cursor: pointer;
  background: $primary;
}

button:hover {
  background: white;
  color: $primary;
}

.logout-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.2rem;
  padding: 5px 10px;
}
</style>
