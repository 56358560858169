<template>
  <div class="audio-player" :class="{ 'audio-player--hidden': shouldHide }">
    <iframe
      src="https://open.spotify.com/embed/playlist/2927OtH0XsDrGGX8vd9tKR"
      width="300"
      height="80"
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'AudioPlayer',
  computed: {
    shouldHide() {
      const hiddenUrls = ['/image-maker']
      const isUserValid = this.$store.state.users.isUserValid
      return !isUserValid || hiddenUrls.includes(this.$route.path)
    },
  },
}
</script>

<style lang="scss">
.audio-player {
  z-index: 11;
  background: black;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 2px solid #424242;
  iframe {
    width: 100%;
  }
  &--hidden {
    display: none;
  }
}
</style>
