<template>
  <nav class="navigation" :class="{ 'navigation--open': isOpen }">
    <ul class="navigation__list">
      <li v-for="link in links" :key="link.url" class="navigation__list__item">
        <router-link :to="link.url">{{ link.text }}</router-link>
      </li>
    </ul>
    <Hamburger v-if="isUserValid" @click="toggleNav" />
    <p class="logged-in-as">Logged in as: {{ user?.email }}</p>
    <button v-if="user" class="logout-btn" :onClick="handleLogout">
      Logout
    </button>
  </nav>
</template>

<script>
import firebase from 'firebase'
import Hamburger from '../assets/icons/Hamburger'

export default {
  name: 'Navigation',
  components: { Hamburger },
  data: () => ({
    links: [
      {
        url: '/',
        text: 'Home',
      },
      {
        url: '/email',
        text: 'Email signature',
      },
      {
        url: '/tips-and-tricks',
        text: 'Tips and Tricks',
      },
      {
        url: '/presentation',
        text: 'Presentation',
      },
      {
        url: '/image-maker',
        text: 'Image Maker',
      },
      {
        url: '/definitions',
        text: 'Wait What does that Mean?',
      },
      {
        url: '/dei-links',
        text: 'DEI Links',
      },
      {
        url: '/tech-ops',
        text: 'Tech Ops',
      },
      {
        url: '/xyz',
        text: 'XYZ',
      },
      {
        url: '/links',
        text: 'Links',
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.users.currentUser
    },
    isUserValid() {
      return this.$store.state.users.isUserValid
    },
    isOpen() {
      return this.$store.state.app.isNavOpen
    },
  },
  methods: {
    handleLogout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch('setUser', null)
          this.$store.dispatch('setNavOpen', false)
          this.$router.push('/authenticate')
        })
    },
    toggleNav() {
      this.$store.dispatch('setNavOpen', !this.$store.state.app.isNavOpen)
    },
  },
}
</script>

<style lang="scss">
.navigation {
  z-index: 9;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -$nav-width;
  width: 250px;
  max-width: 100vw;
  background-color: $primary;
  color: white;
  transition: $duration-nav;
  text-align: center;
  border-right: 1px solid white;

  &__list {
    top: 60px;
    position: relative;

    a {
      font-weight: 700;
    }

    &__item {
      margin: 5px 0;
    }
  }

  .logout-btn {
    position: absolute;
    top: auto;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
  .logged-in-as {
    font-size: 13px;
    position: absolute;
    top: auto;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
  }

  .hamburger {
    position: absolute;
    top: 15px;
    cursor: pointer;
    right: -45px;
    fill: white;

    transition: all 0.2s;
  }

  &--open {
    left: 0;

    .hamburger {
      right: 20px;
      fill: white;
    }
  }
}
</style>
