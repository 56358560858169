<template>
  <main class="homepage">
    <!-- <img alt="A&L logo" src="../assets/logo.jpg" /> -->
    <h1>Arts & Letters</h1>
    <div class="homepage__dashboard">
      <section
        class="homepage__dashboard__section homepage__dashboard__section--links"
      >
        <h2>A&Links 🔗</h2>
        <ul>
          <li v-for="link in links" :key="link.url">
            <a :href="link.url" target="_blank">
              {{ link.text }}
            </a>
          </li>
        </ul>
      </section>

      <section
        class="homepage__dashboard__section"
        v-if="birthdayEmployees.length"
      >
        <h2>Happy Birthday! 🍰</h2>
        <p v-for="employee in birthdayEmployees" :key="employee.name">
          {{ employee.name }}, {{ employee.formattedBirthday }}
        </p>
      </section>

      <section class="homepage__dashboard__section" v-if="newEmployees.length">
        <h2>Welcome Latest Letterheads 👋</h2>
        <p v-for="employee in newEmployees" :key="employee.name">
          {{ employee.name }}
        </p>
      </section>

      <!-- <section class="homepage__dashboard__section">
        <h2 style="margin-bottom: 10px">Comms</h2>
        <p>🚨 NEW WORK ALERT 🚨</p>
        <p>
          Tito’s in Can.
          <a
            href="https://www.instagram.com/reel/CgwWsJlOVTC/"
            class="underline"
            >Check it out here.
          </a>
        </p>

        <p>
          <img src="../assets/logo-outside.svg" alt="quote" height="30" />
          Be a camper and fill out your Outside Best Places to
          <a class="underline">Work Survey</a> by friday.
        </p>
        <p><a href="https://artsandletters.xyz">A&L Site</a></p>
      </section> -->

      <!-- <section
        class="homepage__dashboard__section"
      >
        <h2 style="margin-bottom: 10px">HR</h2>
        <p> 
          Nominate your Vibey 😜 award noms <a class="underline">here</a> by Tuesday&nbsp;Night.
        </p>
      </section> -->

      <!-- <section class="homepage__dashboard__section">
        <h2 style="margin-bottom: 10px">Calendar 📅</h2>
        <p>
          The next company
          <a
            class="underline"
            href="https://calendar.google.com/calendar/u/0/r/eventedit/MmM0c2NrODM1NnM3anJxNW5oYXBmZmZmMmEgbHVrZUBhcnRzYW5kbGV0dGVycy54eXo"
            >Event</a
          >
          is A&L TV Live, on Dec. 14.
        </p>
      </section> -->

      <section class="homepage__dashboard__section">
        <h2 style="margin-bottom:15px">
          Quote <img src="../assets/quote.png" alt="quote" />
        </h2>
        {{ randomQuote }}
        <p>-{{ randomQuoteAuthor }}</p>
      </section>
    </div>
  </main>
</template>

<script>
import config from '../dashboard-config'
import employeeData from '../employeeData.json'
import { mapEmployees } from '../helpers.js'

export default {
  name: 'HomePage',
  data: () => {
    const getDayDiff = (date) =>
      (new Date(`${date}T12:00:00`) - new Date()) / (1000 * 60 * 60 * 24)

    const employees = employeeData.map(mapEmployees)

    return {
      messageOfTheWeek: config.messageOfTheWeek,
      randomQuote: config.randomQuote,
      randomQuoteAuthor: config.randomQuoteAuthor,
      links: config.links,
      birthdayEmployees: employees.filter((e) => {
        const dayDiff = getDayDiff(e.birthday)

        return dayDiff < 1 && dayDiff > -1
      }),
      newEmployees: employees.filter((e) => {
        const dayDiff = getDayDiff(e.workStartDate)

        return dayDiff < 1 && dayDiff > -14
      }),
    }
  },
}
</script>

<style lang="scss">
.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  text-shadow: 3px 3px black;
  text-transform: uppercase;

  img {
    width: 100px;
  }

  h1 {
    margin: 20px 10px 40px;
    font-size: 3rem;
  }

  h2 {
    display: flex;
    flex-direction: row;

    img {
      width: 40px;
      margin-left: 20px;
    }
  }

  &__dashboard {
    columns: 1;

    &__section {
      break-inside: avoid;
      /* display: inline-block; */
      padding: 40px 20px;

      &--links {
        li {
          margin: 3px 0;
        }
      }
    }

    @media only screen and (min-width: 992px) {
      columns: 2;
    }
  }
}
</style>
