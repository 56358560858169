import { createApp } from 'vue'
import firebase from 'firebase'
import App from './App.vue'
import store from './store'
import router from './router'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: 'al-playbook.firebaseapp.com',
  projectId: 'al-playbook',
  storageBucket: 'al-playbook.appspot.com',
  messagingSenderId: '977590401749',
  appId: '1:977590401749:web:4bc320f36ab7c354a3005c',
}

firebase.initializeApp(firebaseConfig)

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
