<template>
  <main class="links-page">
    <div class="loader" :class="{ 'loader--visible': !sections }"></div>

    <ul v-for="(links, section) in sections" :key="section">
      <h3>{{ section }}</h3>
      <li class="definitions__item" v-for="link in links" :key="link.text">
        <a :href="link.url" target="_blank">{{ link.text }}</a>
        <span v-if="link.detail"> - {{ link.detail }}</span>
      </li>
    </ul>
  </main>
</template>

<script>
import { GoogleSpreadsheet } from 'google-spreadsheet'

export default {
  name: 'LinksPage',
  data: () => ({
    sections: null,
  }),
  async created() {
    const doc = new GoogleSpreadsheet(
      '1QGkLu9UMv7W2qF1kPpywX6vBcFz3Khbh-yjKyAaLEWw',
    )

    await doc.useServiceAccountAuth({
      client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
      private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(/\\n/g, '\n'),
    })
    await doc.loadInfo()

    const sheet = doc.sheetsByTitle['Sheet1']
    await sheet.loadCells('A:B')
    const rows = await sheet.getRows()
    const rowCount = rows.length
    const sections = {}
    let currentSection = ''

    for (let i = 1; i < rowCount + 1; i++) {
      const sectionCell = sheet.getCell(i, 0)
      const detailCell = sheet.getCell(i, 1)
      const bgColor = sectionCell.backgroundColor || {}
      const isHeading =
        bgColor.red === 0.63529414 && bgColor.green === 0.76862746

      if (isHeading) {
        currentSection = sectionCell.value
        sections[currentSection] = []
        continue
      }

      if (sectionCell.hyperlink) {
        sections[currentSection].push({
          text: sectionCell.value,
          url: sectionCell.hyperlink,
          detail: detailCell.value,
        })
      }
    }

    for (const [section, links] of Object.entries(sections)) {
      if (!links.length) {
        delete sections[section]
      }
    }

    this.sections = sections
  },
}
</script>

<style lang="scss">
.links-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 50px;

  a {
    text-decoration: underline;
  }
}
</style>
