<template>
  <main class="image-maker-page">
    <h1>Image Maker</h1>

    <div class="inputs">
      <input type="file" @change="onFileSelected" />

      <div class="inputs__select">
        <p>Choose background types:</p>

        <label>
          <input type="checkbox" v-model="backgroundTypes.phones" />
          Phones
        </label>
        <label>
          <input type="checkbox" v-model="backgroundTypes.transit" />
          Transit
        </label>
        <label>
          <input type="checkbox" v-model="backgroundTypes.street" />
          Street
        </label>
        <label>
          <input type="checkbox" v-model="backgroundTypes.billboards" />
          Billboards
        </label>
        <label>
          <input type="checkbox" v-model="backgroundTypes.digital" />
          Digital
        </label>
        <label>
          <input type="checkbox" v-model="backgroundTypes.print" />
          Print
        </label>
      </div>

      <button :disabled="!selectedFile" @click="onUpload">Upload</button>
    </div>

    <div class="loader" :class="{ 'loader--visible': isLoading }"></div>
    <p v-if="isError">Something went wrong, please try again later.</p>

    <div v-if="presentationUrl">
      <h2 v-if="presentationUrl">
        Here is a Google Slides Deck with these comps embeded, make your own
        copy to edit!
      </h2>
      <a :href="presentationUrl" target="_blank">{{ presentationUrl }}</a>
    </div>
    <div v-if="fileUrls" class="results">
      <div
        v-for="backgroundType in Object.keys(fileUrls)"
        :key="backgroundType"
        class="results__row"
      >
        <h3>{{ backgroundType }}</h3>
        <img
          v-for="fileUrl in fileUrls[backgroundType]"
          :key="fileUrl"
          :src="fileUrl"
        />
      </div>
    </div>

    <div>
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=Y19ybjVyZHFianZwMm1wZW5tYmxvZGhzcjRqY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%237986CB"
        style="border:solid 1px #777"
        width="800"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ImageMakerPage',
  data: () => ({
    selectedFile: null,
    backgroundTypes: {
      phones: true,
      transit: false,
      street: false,
      billboards: false,
      digital: false,
      print: false,
    },
    isLoading: false,
    fileUrls: null,
    presentationUrl: null,
    isError: false,
  }),
  methods: {
    onFileSelected(event) {
      this.selectedFile = event.target.files[0]
    },
    onUpload() {
      this.fileUrls = null
      this.presentationUrl = null
      this.isLoading = true
      const { backgroundTypes, selectedFile } = this

      const formData = new FormData()
      formData.append('image', selectedFile, selectedFile.name)
      Object.keys(backgroundTypes).forEach((key) =>
        formData.append(key, backgroundTypes[key]),
      )

      axios
        .post(process.env.VUE_APP_IMAGE_MAKER_API_URL, formData)
        .then((res) => {
          this.fileUrls = res.data.fileUrls
          this.presentationUrl = res.data.presentationUrl
          this.isError = false
          this.isLoading = false
        })
        .catch((err) => {
          this.isError = true
          this.isLoading = false
          console.log('err :>> ', err)
        })
    },
  },
}
</script>

<style lang="scss">
.image-maker-page {
  h2 {
    margin-top: 100px;
    font-size: 2rem;
  }

  a {
    word-wrap: break-word;
  }

  .results {
    h3 {
      text-transform: capitalize;
      margin-top: 80px;
      font-size: 4rem;
    }
  }

  .inputs {
    margin: 20px 0 40px;
    border: 2px solid white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;

    &__select {
      margin: 40px 0;

      label {
        display: block;
      }
    }
  }

  img {
    margin: 40px 0;
  }

  button[disabled] {
    color: rgba(16, 16, 16, 0.3);
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(195, 195, 195, 0.3);
    cursor: not-allowed;
  }
}
</style>
