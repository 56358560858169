export const isUserValid = (user) => {
  return (
    user &&
    user.email &&
    (user.email.endsWith('@artsandletters.xyz') ||
      user.email.startsWith('sergejkurbanov@gmail.com'))
  )
}

export const state = () => ({
  currentUser: null,
  isUserValid: false,
})

export const mutations = {
  SET_USER(state, currentUser) {
    state.currentUser = currentUser
    state.isUserValid = isUserValid(currentUser)
  },
}

export const actions = {
  setUser: ({ commit }, payload) => {
    commit('SET_USER', payload)
  },
}

export default {
  state,
  actions,
  mutations,
}
