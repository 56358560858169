<template>
  <main class="homepage-march">
    <h1>A&L TV</h1>
    <h3>March 26, 2021</h3>

    <div class="homepage-march__dashboard">
      <!-- <section class="homepage-march__dashboard__section">
        <h2>Overheard at A&L <img src="../assets/quote.png" alt="quote" /></h2>
        <p>"You guys can just call me The Oracle if you want."  @Quentin</p>
      </section> -->

      <section class="homepage-march__dashboard__section">
        <h1 class="h1-inline"><u>Agenda</u></h1>
        <h2>•Boring But Important Stuff</h2>
        <h2>•Start Cart</h2>
        <p>&nbsp;&nbsp;• Jacob Pankey</p>
        <h2>•Team Updates</h2>
        <p>&nbsp;&nbsp;• Benihana</p>
        <p>&nbsp;&nbsp;• Firepower</p>
        <p>&nbsp;&nbsp;• &?</p>
        <p>&nbsp;&nbsp;• Spartacus</p>
        <p>&nbsp;&nbsp;• ABC</p>
        <p>&nbsp;&nbsp;• BAMZ</p>
      </section>

      <section class="homepage-march__dashboard__section">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h2>•5 in 5</h2>
        <h2>•Same Faces, New Places</h2>
        <h2>•Awards</h2>
        <h2>&nbsp;</h2>
        <h2>&nbsp;</h2>
        <h2>&nbsp;</h2>
        <h3>&nbsp;&nbsp;&nbsp;Please Remember:</h3>
        <h3>
          &nbsp;&nbsp;&nbsp;Log your time
          <img src="../assets/sparkle-rich.gif" alt="quote" />
        </h3>
        <h2>&nbsp;</h2>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DJ
          LUKE
        </p>
      </section>

      <!-- <section class="homepage-march__dashboard__section">
        <h2>Agenda</h2>
        <p>Super/Boring Important Stuff </p>
        <p> Welcome - Jacob Pankey </p>
        <p>Team Updates</p>
        <p>5 in 5</p>
        <p>Awards</p>
      </section> -->

      <!-- <section class="homepage-march__dashboard__section">
        <h2>Don't Forget</h2>
        <p>
          Remember to upload your A&L photos
          <a
            class="underline"
            href="https://drive.google.com/drive/folders/1gmhJ1o9nNcJki_PBBdHuzmQVzv0Lvf0V"
            target="_blank"
            >here</a
          >.
        </p>
      </section> -->
    </div>
  </main>
</template>

<style lang="scss">
.homepage-march {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  text-shadow: 3px 3px black;
  text-transform: uppercase;

  h1 {
    margin: 20px 10px 10px;
    font-size: 3rem;
  }

  .h1-inline {
    margin-top: 0;
  }

  h2 {
    display: flex;
    flex-direction: row;

    img {
      width: 40px;
      margin-left: 20px;
    }
  }

  h3 {
    display: flex;
    flex-direction: row;

    img {
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
  }

  &__dashboard {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    @media only screen and (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }

    &__section {
      padding: 40px 20px;

      &--links {
        grid-row: 1/-1;

        li {
          margin: 3px 0;
        }
      }
    }
  }
}
</style>
