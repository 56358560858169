export const state = () => ({
  isNavOpen: false,
})

export const mutations = {
  SET_NAV_OPEN(state, isOpen) {
    state.isNavOpen = isOpen
  },
}

export const actions = {
  setNavOpen: ({ commit }, payload) => {
    commit('SET_NAV_OPEN', payload)
  },
}

export default {
  state,
  actions,
  mutations,
}
