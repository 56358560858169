export default {
  messageOfTheWeek: 'Roofdeck temporarily closed',
  randomQuote: "Shepard Screening in Gotham Today!",
  randomQuoteAuthor: ' ❤️ Ops',
  links: [
    { url: 'https://app.hibob.com/home', text: 'HiBob' },

    { url: 'https://www.dominionpayroll.net/', 
    text: 'iSolved Dominion' },
    {
      url: `https://www.advantagehosted.com/webvantage/NewApp`,
      text: 'Advantage',
    },
    {
      url: `https://docs.google.com/spreadsheets/d/1zccnJZBRfFwKEFjgJka0haRHrD97Ehf15BrGyik_uU8/preview`,
      text: 'Job Numbers',
    },
    // {
    //   url: `https://docs.google.com/presentation/d/1sb3hqKp-OCqX2S68vgggNbfIzToWo4V_0SHv-OmZO4A/preview?slide=id.gb4350fa8be_1_112`,
    //   text: 'HR Message About Time',
    // },
    // { url: 'https://www.google.com/maps/d/viewer?mid=1C6Zye2wy1ZfE0c81ZU5sr4uSNdRD819C&ll=37.57101670569954%2C-77.46817399999998&z=19', text: 'Where to Park @ A&L' },
    // {
    //   url: `https://app.fringe.us/login`,
    //   text: 'Fringe Benefits',
    // },
    {
      url: `/tips-and-tricks`,
      text: 'Slack Tips & Tricks',
    },
    {
      url: `definitions`,
      text: 'Wait, What Does That Mean?',
    },
    {
      url: `/onboarding`,
      text: 'Onboarding Touchstone',
    },
    // {
    //   url:`/dei-links`,
    //   text: 'DEI @ A&L',
    // },
    // {
    //   url:``,
    //   text: 'Affinity Groups @ A&L',
    // },
    {
      url: `mailto:helpdesk@artsandletters.xyz`,
      text: 'Open An IT Ticket',
    },
    {
      url: `https://docs.google.com/presentation/u/0/?ftv=1&folder=0ACq40V69tpj6Uk9PVA&tgif=d`,
      text: 'Slide/Presentation Templates',
    },
    // {
    //   url: `mailto:helpdesk@artsandletters.xyz`,
    //   text: 'Have a question and not sure who to ask?',
    // },
    // {
    //   url: `https://artsandletters.xyz`,
    //   text: 'Website',
    // },
  ],
}
