<template>
  <main class="presentation-page" style="margin-top: -20px;">
    <iframe
      src="https://docs.google.com/presentation/d/e/2PACX-1vTgz79in_CTMxRfsHCn7hwy79gYtXAlsU-jxjnM_ayi6eWce2SLuGYx1lNuUdb3VIeWNuJCcLE5J9qC/embed?start=false&loop=false&delayms=6000000"
      frameborder="0"
      width="1440"
      height="839"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    ></iframe>
    <!-- <h1 style="margin: 0">A&L TV</h1>
    <h2 style="text-transform: none">April 30, 2021</h2>

    <div class="presentation__dashboard">
      <section class="presentation__dashboard__section">
        <h2 style="text-shadow: none"><u>Agenda</u></h2>
        <h2 style="margin: 50px 0;">•Boring but important stuff</h2>
        <h2>•Start cart</h2>
        <div style="margin-left: 75px">
          <p>&#9675; &nbsp;Sieya Cammayo</p>
          <p>&#9675; &nbsp;Charles Lee</p>
          <p>&#9675; &nbsp;Lauren Galanides</p>
          <p>&#9675; &nbsp;Kait Sutherland</p>
          <p>&#9675; &nbsp;Justin Andrew Herb</p>
          <p>&#9675; &nbsp;Erin Shepard</p>
          <p>&#9675; &nbsp;Adam Naccarato</p>
          <p>&#9675; &nbsp;Brenda Schneider</p>
          <p>&#9675; &nbsp;Rich Daubner</p>
        </div>
      </section>

      <section class="presentation__dashboard__section" style="padding-left: 70px">
        <br />
        <h2>Team updates</h2>
        <div style="margin-left: 75px">
          <p>&#9675; &nbsp;XYZ</p>
          <p>&#9675; &nbsp;Benihana</p>
          <p>&#9675; &nbsp;Firepower</p>
          <p>&#9675; &nbsp;&?</p>
          <p>&#9675; &nbsp;Spartacus</p>
          <p>&#9675; &nbsp;Team 5</p>
          <p>&#9675; &nbsp;BAMZ</p>
        </div>
        <h2 style="margin-top: 40px;">•Awards</h2>
        <div style="margin-top: 50px; margin-left: 100px;">
          <h2>Please remember:</h2>
          <h2 style="text-transform: none;">
            Log Your Time
            <img src="../assets/sparkle-rich.gif" alt="quote" />
          </h2>
        </div>
      </section>

      <div class="dj-cam">DJ CAM F</div>
    </div> -->
  </main>
</template>

<style lang="scss">
.presentation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  text-shadow: 2px 2px black;
  text-transform: none;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    height: 59vw;
    width: 177.77777778vh;
  }

  .dj-cam {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 2rem;
  }

  .center {
    text-align: center;
  }

  h1 {
    margin: 20px 10px 10px;
    font-size: 4rem;
  }

  h2 {
    display: block;

    img {
      width: 40px;
      margin-left: 0px;
      top: 10px;
      position: relative;
      display: inline-block;
    }
  }

  h3 {
    display: block;

    img {
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
  }

  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }

  h2,
  h3 {
    font-weight: 400;
  }

  &__dashboard {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @media only screen and (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }

    &__section {
      padding: 40px 20px;

      &--links {
        grid-row: 1/-1;

        li {
          margin: 3px 0;
        }
      }
    }
  }

  .section--remember {
    width: 500px;
    align-self: flex-end;
  }
}
</style>
