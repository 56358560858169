<template>
  <main>
    <h2 style="margin: 0 0 100px 0;">Tips and Tricks</h2>


    <img src="../assets/slack.png" alt="quote" height="30" align="left"/>
    
    
    <h3 style="margin: 0px 0 10px 10px; align-self: auto;">
      &nbsp;Slack Tips and Tricks
    </h3>



    <p style="text-decoration:underline; margin: 20px 0 10px;"><a href="https://slack.com/help/categories/360000049063-Slack-tutorials#slack-101">Here is a link to Slack's 101</a></p>



    <h3 style="margin: 40px 0 15px 0;">
      Video - Quick Slack Tips For Getting Started
    </h3>

<iframe width="960" height="569" src="https://www.youtube.com/embed/AZdYHl1olV4?start=58&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <h3 style="margin: 20px 0 15px 0;">
      IT's Slides Deck
    </h3>

<iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTkuZQERqxkcvJKA4zVE9vc1c8ZUOTAzKx21A6g2j0k4eE3rOoUVwp8hZm2PgzdUw/embed?start=false&loop=false&delayms=3000&rm=minimal" frameborder="0" width="960" height="569"></iframe>

    <h3 style="margin: 20px 0 15px 0;">
      Slack Emojis
    </h3>

    <iframe
      src="https://docs.google.com/presentation/d/e/2PACX-1vTw5rinKWkRO5W7ztd0g4e2EJfAfO0MJ0Ry6C4zclAcodPClmI5zZ4oqOxXQ8foD6wmaDpFon5rqCWY/embed?start=false&loop=false&delayms=3000&rm=minimal"
      frameborder="0"
      width="960"
      height="569"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    ></iframe>


    <h3 style="margin: 100px 0px 10px;">
      Details Pending
    </h3>

    <p>
      <ul>
<li>Mute Channels</li>
<li>Sidebar - display prefs and themes</li>
<li>Channel Bookmark bar</li>
<li>How to get an admin to change your channel names.</li>
<li>Keywords</li>
<li>Reminders</li>
<li>Delayed messages</li>
<li>Emoji adventures</li>
<li>Gifs</li>
<li>@ people vs @ here</li>
<li>Huddles</li>
<li>/Hangout + other slash commands</li>
<li>Voice/Video Messages (We haven’t seen anyone use these, not sure if helpful)</li>
<li>Posts ( like this one)</li>
<li>Editing your profile with job title or others</li>
<li>Story behind childhood photos maybe?</li>
<li>Status’</li>
<li>App interrogations like Google Calendar or Forms</li>
</ul>

    </p>

  </main>
</template>


<style lang="scss">
.email-signature-generator-page {
  color: black;

  h2 {
    margin-top: 0;
  }

  .copy-success {
    margin-top: 20px;

    p {
      margin-bottom: 2px;
    }
  }

  .paste-zone {
    padding: 8px;
    outline: none;
    border: 1px solid #666;
    min-height: 170px;
  }

  input {
    display: block;
    padding: 5px;
    outline: none;
    margin: 15px 0;

    &:focus {
      outline: 1px solid #0e2332;
    }
  }

  button {
    border: 2px solid $primary;
    padding: 3px 12px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    background-color: $primary;
    color: white;

    &:hover {
      background-color: white;
      color: $primary;
    }
  }

  a::before,
  a::after {
    display: none;
  }
}
</style>
