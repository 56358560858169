<template>
  <main class="definitions-page">

<h2>A&L Terms and Definitions</h2>

    <Separator />

<div class="loader" :class="{ 'loader--visible': !rows }"></div>

    <ul v-if="rows" class="definitions">
      <li class="definitions__item" v-for="row in rows" :key="row.word">
        <h3>{{ row.word }}</h3>
        <p>
          {{ row.definition }}
          <a v-if="row.link" :href="row.link.url" target="_blank">{{
            row.link.text
          }}</a>
        </p>
      </li>
    </ul>
  </main>
</template>

<script>
import { GoogleSpreadsheet } from 'google-spreadsheet'

export default {
  name: 'ImageMakerPage',
  data: () => ({
    rows: null,
  }),
  async created() {
    const doc = new GoogleSpreadsheet(
      '1Kt65RwUW2hVsOzF4qTDJ9ZqRyShdWYTqE16ArWcd1CY',
    )

    await doc.useServiceAccountAuth({
      client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
      private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(/\\n/g, '\n'),
    })
    await doc.loadInfo()

    const sheet = doc.sheetsByTitle['Primary']
    const rows = await sheet.getRows()
    const definedRows = rows
      .filter((row) => row.DEFINITION)
      .map((row) => {
        const definition = row.DEFINITION.replace(
          /:[^:\s]*(?:::[^:\s]*)*:/g, // remove slack emojis
          '',
        )
        const parts = definition.split(/<[^\s]*\|[a-zA-Z ]*>/) // split by links
        const link = /<([^\s]*)\|([a-zA-Z ]*)>/g.exec(definition)

        return {
          word: row.WORD,
          definition: parts[0].trimStart(),
          link: link && { url: link[1], text: link[2] },
        }
      })

    this.rows = definedRows
  },
}
</script>

<style lang="scss">
.definitions-page {
  .definitions {
    &__item {
      margin-bottom: 50px;
      word-break: break-word;

      a {
        color: rgb(102, 168, 255);
      }
    }
  }
}
</style>
