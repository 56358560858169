<template>
  <div :style="style" class="separator" />
</template>

<script>
export default {
  props: {
    spaceTop: {
      type: [String, Number],
      default: 30,
    },
    spaceBottom: {
      type: [String, Number],
      default: 30,
    },
    isDashed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        marginTop: `${this.spaceTop}px`,
        marginBottom: `${this.spaceBottom}px`,
        width: '100%',
        border: '0.5px solid #EEEEEE',
        borderStyle: this.isDashed ? 'dashed' : 'solid',
      }
    },
  },
}
</script>
