<template>
  <main class="authentication-page">
    <!-- <img alt="A&L logo" src="../assets/logo.jpg" /> -->
    <button v-if="!user" class="login-btn" :onClick="handleLogin">
      Authenticate
    </button>
    <p v-if="invalidUser">
      You don't have permission to view this page. Try logging in as a different
      user.
    </p>
  </main>
</template>

<script>
import firebase from 'firebase'
import { isUserValid } from '../store/modules/users'

export default {
  name: 'AuthenticationPage',
  methods: {
    async handleLogin() {
      let provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account',
      })
      provider.addScope(
        'https://www.googleapis.com/auth/user.organization.read',
      )

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (res) => {
          let user = res.user
          const userData = {
            email: user.email,
            displayName: user.displayName,
          }
          console.log('auth api response :>> ', res)

          const peopleRes = await fetch(
            `https://people.googleapis.com/v1/people/${res.additionalUserInfo.profile.id}?personFields=organizations&access_token=${res.credential.accessToken}`,
          )
          const peopleData = await peopleRes.json()

          console.log('people api response :>> ', peopleData)

          this.$store.dispatch('setUser', userData)
          if (isUserValid(userData)) {
            // Toggle the line below to open menu on log in
            // this.$store.dispatch('setNavOpen', true)
            this.$router.push('/')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  computed: {
    user() {
      return this.$store.state.users.currentUser
    },
    invalidUser() {
      return (
        this.$store.state.users.currentUser &&
        !this.$store.state.users.isUserValid
      )
    },
  },
}
</script>

<style lang="scss">
.authentication-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
  }

  .login-btn,
  p {
    margin-top: 40px;
  }
}
</style>
