<template>
  <svg
    fill="white"
    class="hamburger"
    viewBox="0 0 100 80"
    width="25"
    height="25"
  >
    <rect y="10" width="100" height="10" rx="8"></rect>
    <rect y="40" width="100" height="10" rx="8"></rect>
    <rect y="70" width="100" height="10" rx="8"></rect>
  </svg>
</template>

<script>
export default {
  name: 'Hamburger',
}
</script>

<style lang="scss"></style>
