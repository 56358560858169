import { createWebHistory, createRouter } from 'vue-router'
import HomePage from './pages/HomePage.vue'
import PresentationPage from './pages/PresentationPage.vue'
import PresentationPage2 from './pages/PresentationPage-March26.vue'
import AuthenticationPage from './pages/AuthenticationPage.vue'
import EmailSignatureGeneratorPage from './pages/EmailSignatureGeneratorPage.vue'
import TipsAndTricksPage from './pages/TipsAndTricksPage.vue'
import ImageMakerPage from './pages/ImageMakerPage.vue'
import DefinitionsPage from './pages/DefinitionsPage.vue'
import DEILinksPage from './pages/DEILinksPage.vue'
import TechOpsPage from './pages/TechOpsPage.vue'
import XYZPage from './pages/XYZPage.vue'
import TeamSpartacusPage from './pages/TeamSpartacusPage.vue'
import LinksPage from './pages/LinksPage.vue'
import NotFoundPage from './pages/NotFoundPage.vue'
import store from './store'
import OnboardingPage from './pages/OnboardingPage.vue'
import CommsPage from './pages/CommsPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/presentation',
    name: 'PresentationPage',
    component: PresentationPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/presentation-March26',
    name: 'PresentationPage2',
    component: PresentationPage2,
    meta: { requiresAuth: true },
  },
  {
    path: '/authenticate',
    name: 'Auth',
    component: AuthenticationPage,
    meta: { guest: true },
  },
  {
    path: '/email',
    name: 'Email Signature Generator',
    component: EmailSignatureGeneratorPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/tips-and-tricks',
    name: 'Tips and Tricks',
    component: TipsAndTricksPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/image-maker',
    name: 'Image Maker',
    component: ImageMakerPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/definitions',
    name: 'DefinitionsPage',
    component: DefinitionsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/dei-links',
    name: 'DEILinksPage',
    component: DEILinksPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/tech-ops',
    name: 'TechOpsPage',
    component: TechOpsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/xyz',
    name: 'XYZPage',
    component: XYZPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/spartacus',
    name: 'TeamSpartacusPage',
    component: TeamSpartacusPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/links',
    name: 'LinksPage',
    component: LinksPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/onboarding',
    name: 'OnboardingPage',
    component: OnboardingPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/comms',
    name: 'CommsPage',
    component: CommsPage,
    meta: { requiresAuth: true },
  },
  { path: '/:catchAll(.*)', component: NotFoundPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.users.isUserValid) {
      next()
      return
    }
    next('/authenticate')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.users.isUserValid) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

export default router
