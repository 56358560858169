<template>
  <main class="not-found-page">
    <h1>This page doesn't exist. Tell Luke if you want it to.</h1>
    <p><router-link to="/">home</router-link></p>
  </main>
</template>

<script>
export default {
  name: 'NotFoundPage',
}
</script>

<style lang="scss"></style>
