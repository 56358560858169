<template>
  <main class="tech-ops-page">
    <!-- <img alt="A&L logo" src="../assets/logo.jpg" /> -->
    <h1>Tech Ops</h1>
    <div class="tech-ops-page__dashboard">
      <section
        class="tech-ops-page__dashboard__section tech-ops-page__dashboard__section--links"
      >
        <h2>A&Links 🔗</h2>
        <ul>
          <li v-for="link in links" :key="link.url">
            <a :href="link.url" target="_blank">
              {{ link.text }}
            </a>
          </li>
        </ul>
      </section>

      <!-- <section class="tech-ops-page__dashboard__section">
        <h2>Quote <img src="../assets/quote.png" alt="quote" /></h2>
        {{ randomQuote }}
        <p>-{{ randomQuoteAuthor }}</p>
      </section> -->

      <section class="tech-ops-page__dashboard__section">
        <h2>This week 📰</h2>
        {{ messageOfTheWeek }}

        <p style="margin-top: 15px;">
          Remember to upload your A&L photos
          <a
            class="underline"
            href="https://drive.google.com/drive/folders/1gmhJ1o9nNcJki_PBBdHuzmQVzv0Lvf0V"
            target="_blank"
            >here</a
          >.
        </p>
      </section>

      <section
        class="tech-ops-page__dashboard__section"
        v-if="birthdayEmployees.length"
      >
        <h2>Birthdays 🍰</h2>
        <p v-for="employee in birthdayEmployees" :key="employee.name">
          {{ employee.name }}, {{ employee.formattedBirthday }}
        </p>
      </section>

      <section
        class="tech-ops-page__dashboard__section"
        v-if="newEmployees.length"
      >
        <h2>Welcome 👋</h2>
        <p v-for="employee in newEmployees" :key="employee.name">
          {{ employee.name }}
        </p>
      </section>
    </div>
  </main>
</template>

<script>
import config from '../dashboard-config'
import employeeData from '../employeeData.json'
import { mapEmployees } from '../helpers.js'

const links = [
  { url: 'https://app.hibob.com/home', text: 'HiBob' },

  { url: 'https://www.dominionpayroll.net/', text: 'iSolved Dominion' },
  {
    url: `https://www.advantagehosted.com/webvantage/NewApp`,
    text: 'Advantage',
  },
  {
    url: `https://docs.google.com/spreadsheets/d/1PcLPki5yZ31axo38TBOek3QNRcdza8ZAQ1rfN5--zC4/preview`,
    text: 'Job Numbers',
  },
  {
    url: `https://docs.google.com/presentation/d/1sb3hqKp-OCqX2S68vgggNbfIzToWo4V_0SHv-OmZO4A/preview?slide=id.gb4350fa8be_1_112`,
    text: 'HR Message About Time',
  },
  {
    url:
      'https://www.google.com/maps/d/viewer?mid=1C6Zye2wy1ZfE0c81ZU5sr4uSNdRD819C&ll=37.57101670569954%2C-77.46817399999998&z=19',
    text: 'Where to Park @ A&L',
  },
  {
    url: `https://app.fringe.us/login`,
    text: 'Fringe Benefits',
  },
  {
    url: `https://docs.google.com/presentation/d/1ag298FWAvNW_oCW2qS_oMIP7FFac2Q96PGU9h3y3b9M/present?slide=id.g45e6d48606_0_0`,
    text: 'Day 1 Deck',
  },
  {
    url: `mailto:helpdesk@artsandletters.xyz`,
    text: 'Open An IT Ticket',
  },
]

export default {
  name: 'TechOpsPage',
  data: () => {
    const getDayDiff = (date) =>
      (new Date(`${date}T12:00:00`) - new Date()) / (1000 * 60 * 60 * 24)

    const employees = employeeData.map(mapEmployees)

    return {
      messageOfTheWeek: config.messageOfTheWeek,
      randomQuote: config.randomQuote,
      randomQuoteAuthor: config.randomQuoteAuthor,
      links,
      birthdayEmployees: employees.filter((e) => {
        const dayDiff = getDayDiff(e.birthday)

        return dayDiff < 7 && dayDiff > -1
      }),
      newEmployees: employees.filter((e) => {
        const dayDiff = getDayDiff(e.workStartDate)

        return dayDiff < 1 && dayDiff > -14
      }),
    }
  },
}
</script>

<style lang="scss">
.tech-ops-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  text-shadow: 3px 3px black;
  text-transform: uppercase;

  img {
    width: 100px;
  }

  h1 {
    margin: 20px 10px 40px;
    font-size: 3rem;
  }

  h2 {
    display: flex;
    flex-direction: row;

    img {
      width: 40px;
      margin-left: 20px;
    }
  }

  &__dashboard {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    @media only screen and (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__section {
      padding: 40px 20px;

      &--links {
        grid-row: 1/-1;

        li {
          margin: 3px 0;
        }
      }
    }
  }
}
</style>
