<template>
  <main class="page email-signature-generator-page">
    <h2>Generate your A&L email signature</h2>

    <Separator />

    <label for="phone">
      Edit your phone number below. Click the button to copy the signature to
      your clipboard or select and copy the preview manually.
    </label>
    <input
      id="phone"
      :value="formattedPhone"
      type="tel"
      placeholder="888-888-8888"
      maxlength="12"
      @input="handleChange"
    />
    <button @click="copyToClipboard">
      Copy to clipboard
    </button>

    <div v-if="copiedSuccessfully" class="copy-success">
      <p>Email signature copied successfully! Try pasting it down below. Paste this into your Gmail.com signature settings to apply it to your account.</p>
      <div class="paste-zone" contentEditable="true"></div>
    </div>

    <Separator />

    <div ref="email-content">
      <div style="font-family: Arial, sans-serif;">
        <a
          contenteditable="false"
          target="_blank"
          href="https://artsandletters.xyz/"
          style="font-family: Arial, sans-serif; display: block; margin-bottom: 1px;"
        >
          <img
            src="https://artsandletters.xyz/signature-logos.png"
            style="width: 200px; margin-bottom: 20px; margin-left: 2px; height: 52px;"
          />
        </a>
        <p
          style="font-family: Arial, sans-serif; font-size: 12px; line-height: 11px; margin: 0px 0px 3px; color: rgb(134, 139, 136); max-width: inherit;"
        >
          2700 E Cary Street, Richmond, VA 23223
        </p>

        <p
          style="font-family: Arial, sans-serif; font-size: 12px; line-height: 11px; margin: 4px 0px 0px; color: rgb(134, 139, 136); max-width: inherit;"
        >
          <a
            contenteditable="false"
            target="_blank"
            href="https://artsandletters.xyz/"
            style="color: rgb(134, 139, 136); text-decoration: none;"
            >Website<span>&nbsp; </span></a
          >|<span>&nbsp; </span
          ><a
            contenteditable="false"
            target="_blank"
            href="https://www.instagram.com/artsandletters.xyz/"
            style="color: rgb(134, 139, 136); text-decoration: none;"
            >Instagram<span>&nbsp; </span></a
          >|<span>&nbsp; </span
          ><a
            contenteditable="false"
            target="_blank"
            href="https://www.linkedin.com/company/arts-and-letters-creative-co/"
            style="color: rgb(134, 139, 136); text-decoration: none;"
            >LinkedIn</a
          >
        </p>
        <p
          style="font-family: Arial, sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 11px; margin: 18px 0px 0px; color: rgb(169,170,169); max-width: inherit;"
        >
          <a
            contenteditable="false"
            href="tel:%201%20(804)%20454%207233"
            style="color: rgb(134, 139, 136); text-decoration: none; margin-bottom: 0px;"
            >(o) (804) 454 7233</a
          >
        </p>

        <p
          v-if="outputPhone"
          style="font-family: Arial, sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 11px; margin: 4px 0px 0px; color: rgb(169,170,169); max-width: inherit;"
        >
          <a
            contentEditable="false"
            style="color: rgb(134, 139, 136); text-decoration: none; margin-bottom: 0px;"
            :href="`tel:${outputPhone || '888-888-8888'}`"
          >
            (c) {{ outputPhone }}
          </a>
        </p>

        <p
          style="font-family: Arial, sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-size: 10px; line-height: 16px; margin: 16px 0px 0px; color: rgb(134, 139, 136); max-width: inherit;"
        >
          This message (including any attachments) may contain information that
          is confidential, proprietary, privileged, private, and/or otherwise
          exempt from disclosure under applicable law. The information is
          intended to be for the use of the individual or entity designated
          above. If you are not the intended recipient of this message, please
          notify the sender immediately, and delete the message and any
          attachments. Any disclosure, reproduction, distribution or other use
          of this message or any attachments by an individual or entity other
          than the intended recipient is prohibited.
        </p>
      </div>
    </div>
  </main>
</template>

<script>
import Separator from '../components/Separator.vue'

export default {
  components: { Separator },
  data() {
    return {
      phone: '',
      copiedSuccessfully: false,
    }
  },
  computed: {
    formattedPhone() {
      const { phone } = this

      if (phone.length <= 3) {
        return phone
      } else if (phone.length <= 6) {
        return `${phone.slice(0, 3)}-${phone.slice(3, 6)}`
      }
      return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`
    },
    outputPhone() {
      const { phone } = this

      if (phone.length <= 3) {
        return phone
      } else if (phone.length <= 6) {
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}`
      }
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} ${phone.slice(6)}`
    },
  },
  methods: {
    copyToClipboard() {
      const htmlToCopy = this.$refs['email-content'].innerHTML

      function listener(e) {
        e.clipboardData.setData('text/html', htmlToCopy)
        e.clipboardData.setData('text/plain', htmlToCopy)
        e.preventDefault()
      }

      document.addEventListener('copy', listener)
      document.execCommand('copy')
      document.removeEventListener('copy', listener)

      this.copiedSuccessfully = true
    },
    handleChange(e) {
      const inputValue = e.target.value
      const clearValue = inputValue
        .split('')
        .filter((char) => char !== '-')
        .join('')

      this.phone = clearValue
    },
  },
  metaInfo: {
    meta: [{ name: 'robots', content: 'noindex' }],
  },
}
</script>

<style lang="scss">
.email-signature-generator-page {
  color: black;

  h2 {
    margin-top: 0;
  }

  .copy-success {
    margin-top: 20px;

    p {
      margin-bottom: 2px;
    }
  }

  .paste-zone {
    padding: 8px;
    outline: none;
    border: 1px solid #666;
    min-height: 170px;
  }

  input {
    display: block;
    padding: 5px;
    outline: none;
    margin: 15px 0;

    &:focus {
      outline: 1px solid #0e2332;
    }
  }

  button {
    border: 2px solid $primary;
    padding: 3px 12px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    background-color: $primary;
    color: white;

    &:hover {
      background-color: white;
      color: $primary;
    }
  }

  a::before,
  a::after {
    display: none;
  }
}
</style>
