import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import users from './modules/users'

const store = createStore({
  modules: {
    app,
    users,
  },
  plugins: [createPersistedState({ key: 'al-playbook' })],
})

export default store
